.reportingtree-selector {
  border: 1px solid #ccc;
  width: auto;
  max-width: 236px;
  height: 30px;
  line-height: 1.75;
  padding-left: 5px;
  border-radius: 5px;
  white-space: nowrap;
  padding-right: 5px;
}

.reportingtree-selector:hover {
  border-color: rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  outline: 0;
  outline: thin dotted \9;
}

.reportingtree-selector span {
  display: inline-block;
}

.reportingtree-leaf {
  margin-left: 18px;
}

.reportingtree-child {
  color: #800000;
}

.reportingtree-container {
  position: relative;
}

.reportingtree-container ul {
  margin-left: 5px;
}

.reportingtree-container li {
  list-style: none;
}

.reportingtree-item {
  display: block;
}

.reportingtree-text.reportingtree-disabled {
  color: #999999;
}

.reportingtree-text:hover {
  color: #FFFFFF;
  background-color: #0088cc;
}

.reportingtree-text.disabled:hover {
  color: #999999;
  background-color: #d3d3d3;
}

.reportingtree-dropdown {
  background-color: #fff;
  width: auto;
  height: auto;
  max-height: 500px;
  min-width: 236px;
  overflow-y: scroll;
  cursor: pointer;
  border: 1px solid #ccc;
  box-shadow: 10px 5px 5px #ccc;
  z-index: 1000;
  position: absolute;
  left: 0;
  top: 32px;
}

.reportingtree-active {
  font-weight: 700;
}

.reportingtree-hidden {
  display: none;
}
